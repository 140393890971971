@import '../global/colors.scss';
@import '../global/mixins.scss';
@import '../global/typography.scss';
.client-info {
  @include box-shadow;
  padding: 15px;
  @media screen and (min-width: 768px) {
    min-height: 150px;
    padding: 30px;
  }
  background-color: $layoutWhite;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  .attribute {
    display: flex;
    label {
      width: 150px;
      padding-bottom: 8px;
      font-size: 14px;
      font-family: $sura;
      font-weight: 700;
      color: $layoutBlack;
    }
    p {
      font-family: $roboto;
      font-size: 14px;
    }
  }
}

.client-menu {
  background-color: $layoutWhite;
  mix-blend-mode: normal;
  .option {
    justify-content: flex-start;
    padding-left: 15px;
    height: 50px;
    width: 100%;
    color: black;
    border-radius: 0;
    background-color: $layoutWhite;
    font-size: 16px;
    font-weight: normal;
    text-transform: none;
  }
  .selected {
    color: $layoutWhite;
    background: linear-gradient(90deg, $darkBlue 0%, #74948e 133.33%);
  }
}
